<template>
    <div style="height: 700px; position: relative">
        <div class="vector1"></div>
        <div class="row">
            <div class="xs4"></div>
            <div class="xs8">
                <div class="row justify-end">
                    <div
                        style="
                            width: 264px;
                            height: 66px;
                            padding-right: 27px;
                            padding-top: 40px;
                        "
                    >
                        <img src="../assets/images/logo-sonphat.png" alt="" />
                    </div>
                </div>
                <div
                    style="
                        width: 80%;
                        float: right;
                        padding-top: 70px;
                        height: 80px;
                    "
                    class="text-xs-center"
                >
                    <!-- <div style="font-size: 42px">Xin kính chào quý khách!</div>
                    <div style="font-size: 28px;">
                        Vui lòng quét mã QR từ app<br />PC-Covid19 để tiến hành
                        mua vé!
                    </div> -->
                </div>
                <div
                    class="row justify-end"
                    :style="`${index == 0 ? 'padding-top: 85px' : ''}`"
                    v-for="(item, index) in data.chucNang"
                    :key="index"
                >
                    <DxButton
                        v-if="getManHinh == 'doc'"
                        :id="`btn-chucnang${index}`"
                        :text="item.tenChucNang"
                        type="default"
                        :class="`rounder block btn-chucnang secondary--bg ${
                            index != 0 ? 'mt-4' : ''
                        }`"
                        styling-mode="contained"
                        :style="`width: ${
                            index == 0 ? '75%' : index == 1 ? '85%' : '95%'
                        }`"
                        @click="$router.push('/' + item.chucNangUrl)"
                    />
                    <DxButton
                        v-else
                        :id="`btn-chucnang${index}`"
                        :text="item.tenChucNang"
                        type="default"
                        :class="`rounder block btn-chucnang secondary--bg ${
                            index != 0 ? 'mt-4' : ''
                        }`"
                        styling-mode="contained"
                        :style="`width: ${
                            index == 0 ? '65%' : index == 1 ? '75%' : '85%'
                        }`"
                        @click="$router.push('/' + item.chucNangUrl)"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="body">
        <!--=================================quang cao==============================-->
        <QuangCaoVue
            Height="350px"
            style="border-radius: 10px"
            :ShowNavButton="false"
            :DataSource="data.quangCao"
            class="white--bg shadow pa-2"
        />
        <!--=================================ds nha xe==============================-->
        <DanhSachNhaXeVue class="mt-5" />
        <!--=================================footer==============================-->
        <div class="footer text-xs-center">
            <div class="row justify-space-between">
                <div style="float: left; padding-left: 32px">
                    <div class="row align-center font-24">
                        <DxButton
                            id="targetElement"
                            type="normal"
                            styling-mode="text"
                            icon="mdi mdi-cog"
                            @click="isActionSheetVisible = true"
                        />
                        HỆ THỐNG BÁN VÉ TỰ ĐỘNG
                    </div>
                </div>
                <div style="float: right; padding-right: 32px">
                    <div class="font-16">Copyright © 2021 Sơn Phát</div>
                    <div class="font-16 primary--text">
                        Phiên bản: 1.0.7.2-alphaTest-client
                    </div>
                </div>
            </div>
        </div>
        <DxActionSheet
            :items="actionSheetItems"
            v-model:visible="isActionSheetVisible"
            target="#targetElement"
            :showTitle="false"
            :use-popover="true"
            @itemClick="chonChucNang"
        >
            <template #itemTemplate="{ data }">
                <div class="row align-center">
                    <i :class="`mr-2 mdi mdi-${data.icon}`"></i>
                    {{ data.text }}
                </div>
            </template>
        </DxActionSheet>
    </div>
</template>

<script>
import { DxActionSheet } from "devextreme-vue/action-sheet";
import { DxButton } from "devextreme-vue";
import { reactive } from "vue";
import QuangCaoVue from "../components/QuangCao";
import DanhSachNhaXeVue from "../components/DanhSachNhaXe";

import { mapActions } from "vuex";
export default {
    setup() {
        let data = reactive({ quangCao: [], chucNang: [] });
        return { data };
    },
    components: { DxActionSheet, DxButton, QuangCaoVue, DanhSachNhaXeVue },
    data() {
        return {
            actionSheetItems: [
                { text: "Đăng xuất", value: "DangXuat", icon: "power-standby" },
                { text: "In thử vé", value: "InVe", icon: "printer" },
            ],
            isActionSheetVisible: false,
        };
    },
    methods: {
        ...mapActions("trangChu", [
            "layThongTinQuangCao",
            "layThongTinChucNang",
        ]),
        ...mapActions("banDo", ["clearMap"]),
        chonChucNang(e) {
            if (e.itemData.value == "DangXuat") {
                this.$Core.AuthApi.Logout();
            }
            if (e.itemData.value == "InVe") {
                this.layThongTinVe();
            }
        },
        async layThongTinVe() {
            try {
                this.$startLoading;
                let banTheHienVe = await this.$Core.Api.Make(
                    this.$i18n.t("url.InDemo"),
                    {
                        logoUrl: "",
                        TenDoanhNghiep: "Công ty TNHH A",
                        MaSoThue: "0000000000",
                        DiaChi: "Số 0, Đường X, Thành phố Y, Tỉnh Z",
                        SoDienThoai: "0912345678",
                        TongTien: 120000,
                        GioXuatBen: "12:00",
                        NgayXuatBen: "15/07/2022",
                        BienKiemSoat: "12A-34567",
                        ViTriDo: "",
                        ViTriCho: "G01",
                        TuyenVanChuyen: "Bến xe A - Bến xe B",
                        QrCode: "VeDienTu",
                        MaTraCuu: "ABCD1234",
                        LinkTraCuu: "",
                        KyHieu: "5K22ABC",
                        SoHoaDon: "000001",
                        In2Lien: false,
                    }
                )
                    .Config((c) => {
                        c.responseType = "blob";
                        c.validateStatus = () => true;
                    })
                    .Get();
                if (banTheHienVe.StatusCode == 200) {
                    var banTheHienVe_data =
                        await banTheHienVe.Data.arrayBuffer();
                    var printData = Array.from(
                        new Uint8Array(banTheHienVe_data)
                    )
                        .map((x) => x.toString(16))
                        .map((x) => (x.length == 1 ? "0" + x : x))
                        .join("");

                    if (
                        window.hasOwnProperty("SonPhat") &&
                        SonPhat.canPrintOverRawBT()
                    ) {
                        SonPhat.printPdfRawBT(printData);
                    } else if (
                        window.hasOwnProperty("SonPhat") &&
                        SonPhat.canPrintOverSunmi()
                    ) {
                        SonPhat.printPdfSunmi(printData);
                    } else if (window.hasOwnProperty("SonPhat")) {
                        SonPhat.printPdf(printData);
                    } else {
                        let url = URL.createObjectURL(banTheHienVe.Data);
                        var win = window.open(url, "_blank");
                        win.onload = function () {
                            win.print();
                        };
                    }

                    setTimeout(() => {
                        this.$stopLoading;
                        // this.$router.push("/tim-kiem-chuyen-di");
                    }, 200);
                    // if (ttVe.errorCode != 200) {
                    //     this.error = true;
                    //     return;
                    // }
                    // var banTheHienVes = ttVe.Data.data;
                    // this.layVeInChoMayBanVe(banTheHienVes);
                } else {
                    throw new Error("Không thể lấy bản thể hiện vé");
                }
            } catch (error) {
                this.$stopLoading;
                console.error(error);
            }
        },
    },
    computed: {
        getManHinh() {
            return this.$store.state.ManHinh.manHinh;
        },
    },
    async mounted() {
        //ClearStore
        this.$startLoading;
        this.clearMap();
        this.$store.commit("thongTinThanhToan/resetContext");
        this.$store.commit("timKiemChuyenDi/resetContext");
        this.$store.commit("soDoCho/resetContext");
        this.$store.commit("chiTietChuyenDi/resetContext");
        //Lấy thông tin quảng cáo
        let thongTinQuangCao = await this.layThongTinQuangCao();
        this.data.quangCao = thongTinQuangCao || [];
        //Lấy thông tin quảng cáo
        let chucNang = await this.layThongTinChucNang();
        this.data.chucNang = chucNang || [];
        this.$stopLoading;
    },
};
</script>
<style lang="scss" scoped>
.btn-chucnang {
    height: 88px;
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
    border: 4px solid #ffa49f;
}
::v-deep.btn-chucnang .dx-button-text {
    font-size: 32px !important;
    color: white;
}
.vector1 {
    background-image: url("../assets/images/vector-001.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: -50px;
    position: absolute;
    top: 0;
    width: 100%;
    height: 93%;
}
.body {
    background-image: url("../assets/images/vector-002.jpg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom;
    height: calc(100vh - 700px);
    padding-left: 93px;
    padding-right: 93px;
    overflow: auto;
}
.footer {
    position: absolute;
    bottom: 38px;
    left: 0;
    width: 100%;
}
</style>
