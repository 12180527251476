import BanDo from "./stores/BanDo";
import ThongTinThanhToan from "./stores/ThongTinThanhToan";
import TrangChu from "./stores/TrangChu";
import SoDoCho from "./stores/SoDoCho";
import TimKiemChuyenDi from "./stores/TimKiemChuyenDi";
import ChiTietChuyenDi from "./stores/ChiTietChuyenDi";
import IdleTime from "./stores/plugins/IdleTime"
import ManHinh from "./stores/ManHinh";
export default {
    modules: {
        trangChu: TrangChu,
        thongTinThanhToan: ThongTinThanhToan,
        banDo: BanDo,
        soDoCho: SoDoCho,
        idleTime: IdleTime,
        timKiemChuyenDi: TimKiemChuyenDi,
        chiTietChuyenDi: ChiTietChuyenDi,
        ManHinh
    }
};
